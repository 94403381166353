import React from 'react';
import Image from 'next/future/image';
import { useTranslation } from 'react-i18next';

import { buttonVariants } from '../../UI/Button';
import LinkTo from '@/components/LinkTo';

export default function About() {
  const { t } = useTranslation();

  const sections = [
    {
      title: t('P_HOME.ABOUT.MADE.TITLE'),
      description: t('P_HOME.ABOUT.MADE.DESCRIPTION'),
      src: '/v2/home/manufacturing.jpg',
      cta: t('P_HOME.ABOUT.MADE.CTA'),
      href: '/manufacturing'
    },
    {
      title: t('P_HOME.ABOUT.AREAS.TITLE'),
      description: t('P_HOME.ABOUT.AREAS.DESCRIPTION'),
      src: '/v2/home/areas.jpg',
      cta: t('P_HOME.ABOUT.AREAS.CTA'),
      href: '/areas'
    }
  ];

  return (
    <section className="section full section-y-md bg-sand-100">
      <div className="section-wrapper grid gap-6 md:grid-cols-2">
        {sections.map((section, idx) => (
          <div key={idx} className="space-y-4 md:space-y-8">
            <Image
              alt=""
              src={section.src}
              width={0}
              height={0}
              sizes="100vw"
              className="w-full h-auto rounded-uyn"
            />

            <div className="space-y-6">
              <div className="space-y-3">
                <div className="h3">{section.title}</div>
                <p className="text-body">{section.description}</p>
              </div>

              <LinkTo
                href={section.href}
                aria-label={section.cta}
                className={buttonVariants({ variant: 'dark' })}
              >
                {section.cta}
              </LinkTo>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
